@import '@angular/material/prebuilt-themes/indigo-pink.css';

.cdk-overlay-pane .mat-dialog-container {
  padding: 0;
  width: 90vw;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

* {
  //outline: 1px solid red;
  //outline-offset: -1px;
  transition: all 0.5s ease-in-out;
}


.btn {
  width: fit-content;
  border-radius: 0 !important;
  border: 2px solid rgb(51, 51, 51) !important;
  text-transform: uppercase;
  font-size: 16px;
  padding: 6px 12px;
  font-weight: 400;
  letter-spacing: 2px;

  &:disabled {
    opacity: .65;
  }

  &:hover {
    background: white;
    color: black !important;
  }
}

.btn-border-light {
  border: 2px solid rgb(145, 145, 145) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 8px 25px !important;
}

.btn-border-extralight {
  border: 1px solid rgb(204, 203, 203) !important;
  box-shadow: 5px 5px 5px rgb(224, 224, 224);
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 8px 25px !important;
}

.btn-border-light:hover {
  border: 2px solid rgb(51, 51, 51) !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn:hover {
  background: black;
  color: #fff !important;
}

.btn-inactive {
  border: 2px solid rgb(189, 189, 189) !important;
  color: rgb(189, 189, 189) !important;
}

.btn-inactive:hover {
  background: #fff !important;
  color: rgb(189, 189, 189) !important;
}

.capital {
  text-transform: capitalize !important;
}

.original-text {
  text-transform: none !important;
}

.navbar-toggler {
  display: none !important;
}

.form-group label {
  font-size: 12px;
  font-weight: 500;
}

.form-item {
  border-radius: 0 !important;
  border: 2px solid rgb(134, 134, 134) !important;
  box-shadow: 5px 5px 5px rgb(224, 224, 224);
  padding: 10px 20px !important;
  height: auto;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.form-item-light {
  border-radius: 0 !important;
  border: 1px solid rgb(211, 211, 211) !important;
  box-shadow: 3px 3px 3px rgb(224, 224, 224);
  padding: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}


/* PRELOADER CSS */

.page-loader {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 1000;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  border: 2px solid black !important;
  border-radius: 0 !important;
}


/* SPINNER ANIMATION */

.spinner {
  position: relative;
  top: 40%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-color: rgb(1, 2, 2);
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.no-display {
  display: none !important;
}
